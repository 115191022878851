@media screen and (max-width: 1200px) {
    .top {
        display: none;
    }

    // 轮播图
    .swiperBox {
        .swiper-slide {
            height:310px;
        }
        
        .banner-img {
            height: 310px !important;
            background-size: cover;
            background-position: center ;
            background-repeat: no-repeat;
        }

        .swiper-button-prev, .swiper-button-next{
            display: none;
        }
    }

    .blockBox {
        width: 100%;
        height: 70px;
        visibility:hidden;
    }

    // 移动端 导航栏
    .mobileNav {
        width: 100%;
        height: 70px;
        padding: 15px 30px;

        display: flex;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        top: 0;
        z-index: 100;

        background-color: #FFF;
        box-shadow:0px 1px 10px 0px rgba(0,33,150,0.1);
        
        //Logo
        img {
            width: 135px;
            height: 40px;
        }

        // search and menu
        .buttons {
            img {
                width: 18px;
                height: 18px;
                margin-left: 20px;
            }
        }
    }


    .placeholder {
        height: 70px;
    }

    //Menu
    .mobile-menu{
        height: 100vh;
        width: 100vw;
        position: fixed;
        top: 0px;
        z-index: 100;
        display: flex;
        justify-content: flex-end;

        .menu-item{
            width: 85%;
            background: #FFF;
            padding: 17px 30px;

            .item-header {
                // widows: 100vw;
                // width: 100vw;
                
                display: flex;
                justify-content: space-between;

                .switchBox {
                    width: 159px;
                    height: 36px;
                    border: 1px solid #E8E8E8;
                    border-radius: 4px;

                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .chinese, .english {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        width: 80px;
                        height: 36px;
                        font-size: 12px;
                        color: #979797;

                        img {
                            width: 24px;
                            height: 17px;
                            margin-right: 4px;
                        }
                    }

                    ._choose {
                        //选中的效果
                        border: 0.5px solid #E8E8E8;
                        border-radius: 4px;
                        background-color: #E8E8E8;
                        color: #495057;
                    }
                }


                .switchButton {
                    width: 55px;
                    height: 36px;
                    display: flex;
                    justify-content: space-around;
                    align-items: center;

                    img {
                        width: 18px;
                        height: 18px;
                        margin-top: 1px;
                    }

                    img:first-child {
                        margin-right: 21px;
                    }
                }


            }

            hr {
                border: 0.5px solid #E9ECEF;
                margin-top: 16px;
            }

            // tree位置
            .f-tab>.ant-tree-node-content-wrapper-open{
                color: #004AD8;
                height: 30px;
                width: 100%;
                border-bottom: 1px solid #004AD8;
            }
        }
    }

    //Menu shadow
    .mobile-menu::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        height: 100%;
        z-index: -1;
        background: rgba(0, 0, 0, 0.3);
    }
    //Menu search
    .mobile-search{
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0px;
        z-index: 100;
        background: rgba(0, 0, 0, 0.3);

        .box-shadow {
            height: 120px;
            width: 100vw;
            background-color: #fff;
            padding: 17px 30px;

            .search-header{
                height: 50px;

                .switchButton {
                    float: right;
                    width: 55px;
                    height: 36px;
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
        
                    img {
                        width: 18px;
                        height: 18px;
                        margin-top: 1px;
                    }
        
                    img:first-child {
                        margin-right: 19px;
                    }
                }
            }

            //input
            .search-ipt{
                position: relative;

                ._search{
                    padding: 0 5px;
                    width: 100%;
                    background: #F8F9FA;
                    border: 1px solid #E9ECEF;
                    height: 35px;
                    
                }
                ._btn{
                    position: absolute;
                    top: 9px;
                    right:10px;
                }
            }

            .resultList {
                position: absolute;
                left: 0;
                padding: 30px;
                margin-top: 18px;
                width: 100vw;
                height: 100vh;
                overflow-y:auto;

                background-color: #F8FBFF;

                ul {
                    padding: 0px;
                    background-color: #F8FBFF;
                    li {
                        padding: 10px 0;
                        border-bottom: 1px dashed gray;
                        a {
                            color: #1B1464;
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }

    // 底部
    .bottomContainer {
        .bottomInner {
            width: 100vw;
            height: 350px;
            padding-top: 10px;
            flex-wrap: wrap;
            justify-content: center;

            img {
                width: 100vw;
                height: 68px;
                margin: 0;
                margin-bottom: 10px;
            }

            .linkTable {
                width: 210px;
                margin: 0;

                ul {
                    li {
                        margin: 5px 0;
                    }
                }

                span {
                    font-size: 16px;
                }

                ul > li > a {
                    font-size: 14px;
                }
            }

            .copyright {
                font-size: 12px;
            }
        }

        .bottomInner div:nth-last-child(2) {
            width:100px;
            margin-left: 20px;
        }
    }

    // 首页
    .overview {
        .swiperBox {
            width: 100vw;
        }

        .content {
            p {
                font-size: 16px;
                font-weight: bold;
                padding-top: 50px;
            }

            .cardBox {
                width: 100vw;
                padding: 10px;
                margin-bottom: 30px;

                flex-wrap: wrap;
                
                .card {
                    width: 168px;
                    height: 224px;
                    margin-bottom: 20px;

                    span {
                        font-size: 14px;
                        line-height: 35px;
                    }
                }
            }
        }
    }

    // 公司简介
    .aboutUs {
        width: 100vw;

        .banner {
            width: 100vw;
            height: 310px;
        }

        .paper {
            div {
                width: 100vw;
                padding: 30px;
                font-size: 14px;
            }
        }
    }

    // 新闻
    .newsPages {
        width: 100vw;
        padding: 0 20px;
    }

    // 产品、解决方案、下载
    .mianContainer {
        width: 100vw;
        margin-top: 10px;

        .containerInner {
            width: 100vw;

            p {
                margin-bottom: 8px;
                padding-left: 20px;
            }

            hr {
                display: none;
            }

            // 子菜单
            .breadNav {
                display: none;
            }

            //产品
            .productMain {
                width: 100vw;
                margin: 0;

                .ant-pagination {
                    width: 300px;
                    margin: 30px auto;
                    text-align: center;
                }

                .list {
                    border-top: 0.5px solid #E8E8E8;
                    border-bottom: 0.5px solid #E8E8E8;
                    padding: 20px;
                    margin-bottom: 0;

                    img {
                        width: 82px;
                        height: 82px;
                    }

                    dl {
                        width: 240px;
                        margin: 0;
                        margin-left: 10px;
                        
                        dt {
                            font-size: 14px;
                            font-weight: normal;
                            margin-bottom: 5px;
                        }

                        span {
                            display: none;
                        }
                    }
                }

                //产品文章
                .productDetail {
                    border-top: 1px solid #E8E8E8;
                    padding: 20px;

                    word-break: break-all;
    
                    h3 {
                        font-size: 16px;
                    }

                    img {
                        width: 100% !important;
                    }

                    p {
                        padding: 0;
                    }
                }
            }

            //解决方案
            .solutionMain {
                width: 100vw;
                padding: 20px;
                margin: 0;
                border-top: 1px solid #E8E8E8;

                img {
                    width: auto;
                    height: auto;
                    max-width: 100%;
                    max-height: 100%;
                }
            }

            //下载中心
            .downloadMain {
                width: 100vw;
                margin: 0;

                .searchBox {
                    display: none;
                }

                .remarks {
                    display: none;
                }

                label {
                    display: none;
                }

                h3 {
                    display: none;
                }

                br {
                    display: none;
                }

                ul {
                    li {
                        padding-left: 20px;
                        display: flex;
                        align-items: center;

                        height: 60px;
                        margin: 0;
                        border-bottom: 0.5px solid #E8E8E8;

                        img {
                            width: 28px;
                            height: 37px;
                        }
                    }

                    li:nth-child(1) {
                        border-top: 0.5px solid #E8E8E8;
                    }
                }
            }
        }
    }

    // 联系我们
    .contactStyle {
        width: 100vw;
        padding: 0;

        .container {
            width: 100vw;

            p {
                display: none;
            }

            .map {
                width: 100vw;
            }

            .info {
                width: 100vw;
                margin: 0;
                padding: 20px;
            }
        }
    }
}
