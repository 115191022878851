// 全局样式
html,body,#root{
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    font-family: "PingFang SC", "Helvetica Neue", "Arial", "Microsoft YaHei", "Hiragino Sans GB";
    background: #F8FBFF;
    user-select: none;
}

main {
    min-height: 560px;
}

ul {
    list-style: none;
}

a {
    text-decoration:none;
    color: #1B1464;
}

//去除 Chrome 下的蓝框
input:focus{outline:none}
button:focus{outline:none}

// input 默认字体效果
::placeholder { 
    color: #CED4DA;
    opacity: 1; 
}

//隐藏手机端头部样式
.mobileNav {
    display: none;
}

// Header
.top {
    display: block;
    width: 100%;
    background-color: #fff;

    display: flex;
    justify-content: space-between;

    .topNavBar {
        width: 1200px;
        height: 90px;
        margin: 0 auto;
        
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        .logoAndMenu {
            display: flex;
            align-items: center;

            img {
                margin-left: 40px;
            }
        
            a {
                color: #1B1464;
                font-size: 16px;
            }
        
            ul {
                height: 16px;
    
                li {
                    font-weight: normal;
                    line-height: 35px;
                    margin-right: 30px;
                    float: left;

                    position: relative;
                    text-align: center;
                }

                li:hover a{
                    color: #004AD8;
                }

                li:after{
                    content: "";
                    width: 0;
                    height: 2px;
                    background: #004AD8;
                    position: absolute;
                    top: 100%;
                    left: 50%;
                    transition: all 0.3s;
                }

                li:hover:after{
                    left: 0%;
                    width: 100%;
                    border-radius: 5px;
                }
                //选中状态
                ._select:after{
                    left: 0%;
                    width: 100%;
                }

                ._select a{
                    color: #004AD8;
                }
            }
        }

        .conversionPanel {
            width: 230px;
            height: 40px;
            background-image: url(../assets/Conversion.png);
            background-size: cover;

            align-self: flex-start;
            // margin-left: 100px;
        
            display: flex;
            justify-content: center;
            align-items: center;
        
            span {
                margin: 0 15px;
                font-size: 20px;
                color: #c8c8c8;
                font-weight: normal;
            }
        
            div {
                display: flex;
                align-items: center; 
                font-size: 14px;
                color: #495057;
                transition: all 0.2s;
        
                img {
                    width: 22px;
                    height: 15px;
                    margin: 0;
                    margin-right: 8px;
                }
            }

            div:hover {
                color: rgba(0, 0, 0, 0.4);
            }
        
            .zh, .us {
                cursor: pointer;
            }
        }
    }
}

// bottom style
.bottomContainer {
    width: 100%;
    color: white;

    .bottomInner {
        height: 290px;
        max-width: 2000px;
        margin: 0 auto;
        padding-bottom: 8px;

        background-image: url(../assets/bottom.svg);
        background-size: cover; 
        background-position: center;

        display: flex;
        justify-content: center;
        align-items: flex-end;
        flex-wrap: wrap;

        img {
            width: 110px;
            height: 100px;
            margin-right: 130px;
        }

        .linkTable {
            width: 350px;
            height: 150px;
            margin: 0 30px;

            span {
                font-size: 20px;
                font-weight: bold;
            }

            hr {
                border: 0.5px solid rgba(255, 255, 255, 0.2);
            }

            ul {
                padding: 0;

                li {
                    margin: 8px 0;
    
                    a{
                        font-size: 18px;
                        font-weight: 300;
                        color: white;
                    }
    
                    a:hover {
                        color: rgba(255, 255, 255, 0.5);
                    }
                }
            }
        }

        .copyright {
            width: 100%;
            text-align: center;
            color: rgba(255, 255, 255, 0.5);
            padding: 0;

            p{
                margin: 0;
            }
            
            hr {
                border: 1px solid rgba(255,255,255,0.2);
            }
        }
    }
}

// 首页样式
.overview {
    width: 100%;
    background-color: #004AD8;

    .swiperBox {
        width: 1200px;
        margin: 0 auto;

        .banner-img{
            height: 450px;
            width: 100%;
        }

        .swiper-button-prev {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg' viewBox%3D'0 0 27 44'%3E%3Cpath d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z' fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E") !important;
        }
        
        .swiper-button-next {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg' viewBox%3D'0 0 27 44'%3E%3Cpath d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z' fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E") !important;
        }

        //轮播图  点
        .swiper-container .swiper-pagination-bullet{
            background:rgba(27,20,100,0.8);
        }

        //轮播图 选中的点
        .swiper-container .swiper-pagination-bullet-active{
            width: 40px;
            border-radius: 10px;
        }
    }

    .content {
        background-color: #F8FBFF;
    
        p {
            text-align: center;
            font-size: 30px;
            font-weight: bold;
            color:rgba(27,20,100,1);
            padding-top: 50px;
        }

        .cardBox {
            width: 1200px;
            margin: 0 auto;
            margin-bottom: 150px;
            
            display: flex;
            justify-content: space-around;
            
        
            .card {
                width: 270px;
                height: 360px;
                
                background-size: cover;
                background-position:center;
                box-shadow:0px 5px 10px 0px rgba(0,33,150,0.1),0px 0px 15px 10px rgba(0,71,207,0.05);
                border-radius:8px;
        
                display: flex;
                align-items: flex-end;
                justify-content: center;
        
                span {
                    color: #FFF;
                    font-size: 20px;
                    text-align: center;
                    line-height: 60px;
                }
            }
            a{
                transition:all .3s ;
            }
            a:hover {
                border-radius:8px;
                box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.25);
                transform: translateY(-3px);
            }
        }
    }
}

// 关于我们
.aboutUs {
    width: 100%;
    background-color: #004AD8;

    .banner {
        width: 1200px;
        height: 450px;
        margin: 0 auto;

        background: url(http://joy-free.com/images/company/Group.png);
        background-size: cover;
        background-position:center;
    }

    .paper {
        background-color: #F8FBFF;
        
        div {
            width: 1200px;
            margin: 0 auto;
            padding: 50px 0 150px 0;
            line-height: 23px;
        }
        
    }
}

// 产品、解决方案、下载页面样式
.mianContainer {
    width: 100%;
    margin-top: 30px;
    color: #1B1464;
    min-height: 600px;

    .containerInner {
        width: 1200px;
        margin: 0 auto;

        display: flex;
        flex-wrap: wrap;

        p {
            width: 100%;
            font-size:16px;
        }

        hr {
            width: 100%;
            border: 0.5px solid rgba(27,20,100,0.2);
        }

        .breadNav {
            width: 270px;
            

            hr {
                border: 0.5px solid rgba(27,20,100,0.2);
            }

            p {
                color: #1B1464;
                font-size: 20px;
                font-weight: bold;
                margin-top: 20px;
            }

            // tree 换行
            .ant-tree li .ant-tree-node-content-wrapper{
                height:auto;
            }

            .ant-tree-node-content-wrapper{
                white-space: normal;
                max-width: 100%;
            }

            .navSearch {
                margin: 20px 0;

                display: flex;
                align-items: center;

                span {
                    font-size: 18px;
                    font-weight: Bold;
                    margin-right: 15px;
                }

                input[type="text"] {
                    height:32px;
                    background:rgba(255,255,255,1);
                    border-radius:100px;
                    border:1px solid rgba(222,226,230,1);
    
                    color: #1B1464;
                    font-size: 16px;
                    padding-left: 15px;
                }

                button{
                    width: 18px;
                    height: 18px;
                    margin-left: -30px;
                    background-image: url(../assets/navSearch.svg);
                    background-color: transparent;
                    cursor: pointer;
                    border: none;
                }
            }

            .t-father > .ant-tree-node-content-wrapper,
            .news-t-father > .ant-tree-node-content-wrapper
            {
                color: #1B1464;
                font-size: 18px;
                font-weight: bold;
            }

            .news-t-father > .ant-tree-switcher{
                width: 0px;
            }

            .ant-tree-node-content-wrapper-normal {
                font-size: 16px;
                font-weight: normal;
            }
        }
        //文章默认高度
        .productMain,.solutionMain,.downloadMain{
            min-height: 350px;
        }
        
        .productMain {
            width: 870px;
            margin: 40px 0 20px 60px;
            
            .list {
                display: flex;
                flex-wrap: wrap;
                align-items: center;

                margin-bottom: 50px;

                img {
                    width: 140px;
                    height: 140px;
                }

                dl {
                    width: 690px;
                    margin: 0px;
                    margin-left: 40px;

                    dt {
                        font-size:18px;
                        color: #1B1464;
                        margin-bottom: 12px;
                    }

                    dd {
                        font-size: 14px;
                        color: #495057;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                    }

                    span {
                        color: #1B1464;
                    }

                    span:hover {
                        color: #004AD8;
                    }
                }
            }

                // 分页符样式
            .ant-pagination {
                margin-bottom: 50px;
                a {
                    color:#495057;
                }

                .ant-pagination-prev, .ant-pagination-next {
                    a {
                        color: #1B1464;
                    }

                    a:hover {
                        color: #004AD8;
                    }
                }

                .ant-pagination-item {
                    border: 1px solid transparent;;
                    border-radius: 50%;
                    background-color: transparent;
                }

                .ant-pagination-item-active {
                    background-color: #004AD8;

                    a {
                        color: #FFF;
                    }
                }
            }

            .list:hover{
                cursor: pointer;
            }

            .ant-pagination {
                width: 400px;
                margin: 0 auto;
            }

            .productDetail {
                color: #495057;

                h3 {
                    font-size: 18px;
                    color:#1B1464;
                }
            }
            
        }

        .solutionMain {
            width: 870px;
            margin: 40px 0 0 60px;
            color: #495057;
        }

        //Download Pages
        .downloadMain {
            width: 580px;
            margin: 30px 0 150px 200px;
            
            .searchBox {
                display: flex;
                align-items: center; 

                input[type="text"] {
                    width:580px;
                    height:42px;
                    background:rgba(255,255,255,1);
                    border-radius:100px;
                    border:1px solid rgba(222,226,230,1);

                    color: #1B1464;
                    font-size: 16px;
                    padding-left: 25px;
                }

                button{
                    width:100px;
                    height: 42px;
                    margin-left: -100px;

                    background-image: url(../assets/search.png);
                    background-size: cover;
                    border-radius:100px; 
                    border: none;
                    cursor: pointer;
                }
            }

            label {
                margin-right: 20px;
            }

            .remarks {
                color: #495057;
                font-size: 14px;
                margin: 20px 0 40px 0;
            }

            h3 {
                color: #1B1464;
                font-size: 18px;
                font-weight: normal;
            }

            ul {
                padding: 0;
                li {
                    margin-bottom: 16px;

                    a{
                        color: #495057;
                    }

                    img {
                        width: 20px;
                        height: 25px;
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}

// 新闻页面样式
.newsPages {
    width: 1200px;
    margin: 0 auto;
    font-size: 16px;
    line-height: 40px;
    padding-top: 30px;
    min-height: 610px;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    article {
        width: 1200px;
    }

    strong {
        font-size: 16px;
        padding-right: 15px;
        border-right: 1px solid #E8E8E8;
        margin-right: 15px;
    }

    img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
    }

    // 分页符样式
    .ant-pagination {
        margin-bottom: 50px;
        a {
            color:#495057;
        }

        .ant-pagination-prev, .ant-pagination-next {
            a {
                color: #1B1464;
            }

            a:hover {
                color: #004AD8;
            }
        }

        .ant-pagination-item {
            border: 1px solid transparent;;
            border-radius: 50%;
            background-color: transparent;
        }

        .ant-pagination-item-active {
            background-color: #004AD8;

            a {
                color: #FFF;
            }
        }
    }

    p {
        margin-top: 20px;
    }
}

// 联系我们
.contactStyle {
    width: 100%;
    min-height: 610px;
    color: #1B1464;
    padding-bottom: 130px;

    .container {
        width: 1200px;
        margin: 0 auto;

        display: flex;
        flex-wrap: wrap;
        align-items: center;

        p {
            width: 100%;
            padding-top: 30px;
            color: #1B1464;
            font-size: 16px;
        }

        .map {
            width: 720px;
            height: 350px;
        }

        .info {
            margin-left: 45px;

            span {
                width: 100%;
                font-size: 20px;
                font-weight: bold;
            }


            ul {
                margin-top: 15px;
                padding: 0;

                svg {
                    margin-right: 8px;
                }

                li {
                    display: flex;
                    align-items: center;
                    height: 35px;
                    user-select: text;
                }
            }
        }
    }
}

// // 客服组件
// .floatBall {
//     float: right;
//     position:fixed;
//     right: 20px;

//     .floatBallInner {
//         width:100px;
//         height: 222px;
//         background-image: url(../assets/floatingball.svg);

//         display: flex;
//         flex-wrap: wrap;
//         align-items: center;

//         div {
//             text-align: center;
//             width: 100px;
//             cursor: pointer;
//         }

//         div > p {
//             color: #FFF;
//             font-size: 14px;
//             font-weight: bold;
//             padding: 0;
//             margin: 0;
//         }

//         img {
//             width: 32px;
//             height: 32px;
//         }
//     }
// }
